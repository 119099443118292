import seasfile from '../../images/sponsors/seasLogo.png'
import infosysfile from '../../images/sponsors/infosys.png'
import sunstonefile from '../../images/sponsors/sunstone.png'
import masscecfile from '../../images/sponsors/masscec.png'
import dixiefile from '../../images/sponsors/dixie.png'
import strfile from '../../images/sponsors/str.png'
import _8020file from '../../images/sponsors/8020.png'
import oosfile from '../../images/sponsors/officeofsustainability.png'
import livinglabfile from '../../images/sponsors/livinglab.png'
import expresspcbfile from '../../images/sponsors/expresspcb.png'
import grabcadfile from '../../images/sponsors/grabcad.png'
import aetfile from '../../images/sponsors/aet.png'
import bobfile from '../../images/sponsors/bobo.png'
import monsterfile from '../../images/sponsors/monster.png'
import gogofile from '../../images/sponsors/gogo.png'
import kindfile from '../../images/sponsors/kind.png'
import hintfile from '../../images/sponsors/hint.png'
import fritolayfile from '../../images/sponsors/fritolay.png'
import whooplogo from '../../images/sponsors/whooplogo.png'
import bmwlogo from '../../images/sponsors/bmwlogo.png'
import biogenlogo from '../../images/sponsors/biogenlogo.png'
import mineraltreelogo from '../../images/sponsors/mineraltreelogo.png'
import stratasyslogo from '../../images/sponsors/stratasyslogo.png'
import nLightLogo from '../../images/sponsors/nLIGHT.png'
import raytheonLogo from '../../images/sponsors/raytheon.png'

export default {
  tier1: [
    {
      src: infosysfile,
      alt: 'Infosys'
    },
    {
      src: nLightLogo,
      alt: 'nLight'
    }
  ],
  tier2: [
    {
      src: raytheonLogo,
      alt: 'WHOOP'
    },
    {
      src: bmwlogo,
      alt: 'Massachusetts Clean Energy Center'
    },
    { src: biogenlogo, alt: 'Dixie Chemical' },
    {
      src: mineraltreelogo,
      alt: 'Systems & Technology Research'
    },
    {
      src: _8020file,
      alt: '80/20'
    }
  ],
  tier3: [
    {
      src: aetfile,
      alt: 'AET Labs'
    },
    {
      src: stratasyslogo,
      alt: 'stratasys'
    },
    {
      src: grabcadfile,
      alt: 'GrabCAD Print'
    }
  ]
}
