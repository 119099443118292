import React from 'react'
import { Container, Apply } from './styles'

const AboutButton = ({ mobile }) => (
  <Container
    target="_blank"
    mobile={mobile}
    to="https://forms.gle/W38a2dUjsjwbr6Xv9"
  >
    <Apply>APPLY FOR 2025 SEASON</Apply>
  </Container>
)

export default AboutButton
