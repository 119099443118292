import styled from 'styled-components'
import theme from '../../theme'

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;

  @media only screen and (max-width: 1060px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const Title = styled.h1`
  position: absolute;
  top: -0.5em;
  left: 0.5em;
  font-family: ${theme.fonts.bold.family};
  font-weight: ${theme.fonts.bold.weight};
  font-size: 8vw;
  font-style: italic;
  color: ${theme.colors.fonts.primary};
`

export const InstaImage = styled.img`
  object-fit: cover;
  width: 130px;
  height: 130px;
`

export const ImageRow = styled.div`
  margin-bottom: 20px;
  position: absolute;
  display: flex;

  &:nth-child(1) {
    left: 20px;
  }

  &:nth-child(2) {
    left: 20px;
    top: 150px;
  }
  &:nth-child(3) {
    left: 20px;
    top: 300px;
  }
  position: relative;
    top: 0 !important;
    left: 0 !important;
`

export const InstagramWrapper = styled.div`
  position: relative;
  height: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  overflow: hidden;

`

export const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-right: 50px;
  width: 50vw;
  box-sizing: border-box;
  
  margin-bottom: 30px;
  max-width: 800px;
  padding: 50px;
  width: 100%;
  align-items: center;

`

export const Header = styled.h1`
  font-family: ${theme.fonts.body.family};
  font-weight: ${theme.fonts.bold.weight};
  font-size: 40px;
  font-style: italic;
  text-align: left;
  color: ${({ accent }) =>
    accent ? theme.colors.fonts.accent : theme.colors.fonts.primary};
  text-align: center;
`

export const Body = styled.p`
  font-family: ${theme.fonts.body.family};
  font-weight: ${theme.fonts.body.weight};
  font-size: 20px;
  line-height: 29px;
  text-align: left;
  color: ${theme.colors.fonts.primary};
  margin: 30px 0;

  @media only screen and (max-width: 1060px) {
    text-align: center;
  }
`

export const PhotosLink = styled.a`
  font-family: ${theme.fonts.body.family};
  font-weight: ${theme.fonts.bold.weight};
  font-size: 20px;
  font-style: italic;
  line-height: 37px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  width: 200px;
  text-decoration: none;
  text-align: center;
  color: ${theme.colors.fonts.primary};

  -webkit-box-shadow: 10px 10px 0px 0px rgba(0,0,0,0.52);
  -moz-box-shadow: 10px 10px 0px 0px rgba(0,0,0,0.52);
  box-shadow: 8px 8px 0px 0px rgba(255,255,255,0.52);
  background-color: ${theme.colors.layout.primary};
  transition: background-color 0.225s ease;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${theme.colors.layout.secondary};
  }
`

export const ImageOverlay = styled.div`
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  width: 130px;
  height: 130px;
  position: absolute;
  opacity: 0;
  background-color: ${theme.colors.layout.imageOverlay};
  transition: opacity 0.225s ease;
  z-index: 1;
  padding: 20px 10px;
  text-align: center;
  box-sizing: border-box;
  cursor: default;
  user-select: none;

  font-family: ${theme.fonts.body.family};
  font-weight: ${theme.fonts.body.weight};
  font-size: 14px;
  color: ${theme.colors.fonts.primary};
`

export const ImageWrapper = styled.div`
  width: 130px;
  height: 130px;
  position: relative;
  margin-right: 20px;
  background: green;

  &:hover {
    ${ImageOverlay} {
      opacity: 1;
    }
  }

  &:last-child {
    margin-right: 0;
  }
`
