import styled from 'styled-components'
import theme from '../../theme'

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 30px;
  background: #2F3242;
`

export const Title = styled.h1`
  position: absolute;
  top: -0.6em;
  left: 0.5em;
  font-family: ${theme.fonts.bold.family};
  font-weight: ${theme.fonts.bold.weight};
  font-size: 10vw;
  letter-spacing: 0.3em;
  font-style: italic;
  color: ${theme.colors.fonts.primary};
`

export const Question = styled.h4`
  font-family: ${theme.fonts.body.family};
  font-weight: ${theme.fonts.bold.weight};
  font-style: italic;
  color: ${theme.colors.fonts.accent};
  font-size: 21px;
  margin-bottom: 15px;
  user-select: none;
`

export const QuestionWrapper = styled.div`
  padding: 40px 40px;
  margin: 20px;
  width: 50%;
  background-color: #444858;
  box-shadow: 8px 8px 0px 0px rgba(255,255,255,0.52);
  transition: all 0.225s ease;
  color: ${ theme.colors.fonts.primary };
`

export const Header = styled.h1`
  font-family: ${theme.fonts.body.family};
  font-weight: ${theme.fonts.bold.weight};
  font-size: 40px;
  font-style: italic;
  text-align: left;
  color: ${({ accent }) =>
    accent ? theme.colors.fonts.accent : theme.colors.fonts.primary};
  text-align: center;
`

export const Answer = styled.p`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: auto;
  font-family: ${theme.fonts.body.family};
  font-weight: ${theme.fonts.body.weight};
  font-size: 16px;
  color: ${theme.colors.fonts.primary};
`
