import styled from 'styled-components'
import theme from '../../theme'

export const NavTarget = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1em;
  width: 1em;
  padding: 0.1666em 0;
  overflow: hidden;
  transition: transform 0.3s ease;
  margin: 0;
`

export const NavSlice = styled.span`
  position: relative;
  left: 0;
  display: block;
  width: 1em;
  height: 0.1em;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 0.1em;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    background: white;
    transition: left 0.3s ease;
    border-radius: 0.1em;
  }

  &:nth-child(2):after {
    transition-delay: 0.075s;
  }
  &:nth-child(3):after {
    transition-delay: 0.15s;
  }
`

export const NavItem = styled.div`
  position: relative;
  right: -210px;
  transform: translateX(0);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
  cursor: pointer;

  font-family: ${theme.fonts.body.family};
  font-weight: ${theme.fonts.body.weight};
  color: ${theme.colors.fonts.primary};

  &:nth-child(3) {
    transition-delay: 0.075s;
  }
  &:nth-child(4) {
    transition-delay: 0.15s;
  }

  &:hover {
    color: ${theme.colors.fonts.accent};
  }
`

export const Container = styled.nav`
  font-size: 36px;
  height: 250px;
  position: fixed;
  top: 50px;
  right: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 999;

  &.open {
    ${NavItem} {
      opacity: 1;
      transform: translateX(-210px);
    }
    ${NavTarget} > ${NavSlice}:after {
      left: -101%;
    }
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`
