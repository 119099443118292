// Questions

export default [
  {
    question: 'Who can apply to MakeHarvard?',
    answer: 'Current undergraduate and graduate students can apply to MakeHarvard! We accept students from all academic backgrounds. Many of our competitors have experience with mechanical, electrical, or computer engineering.'
  },
  {
    question: 'Where will MakeHarvard be held?',
    answer: 'This year the event will be in Harvard\'s newly-built Science and Engineering Complex in Allston.'
  },
  {
    question: 'What prizes can I win?',
    answer: 'We\'ll be awarding 1st, 2nd, and 3rd place Overall awards as well as several side categories including Best Pitch and Most Sustainable. We\'ll be offering about $7,000 of prizes across the different competition categories.'
  }
]
