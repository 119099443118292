import React from 'react'
import Landing from '../components/Landing'
import Sponsors from '../components/Sponsors'
import Copyright from '../components/Copyright'
import SEO from '../components/seo'
import FAQ from '../components/FAQ'
import About from '../components/About'
import { PageContainer } from '../components/pageStyles'
import '../reset.css'

const IndexPage = () => (
  <PageContainer>
    <SEO title="MakeHarvard" />
    <Landing />
    <About />
    <FAQ />
    <Sponsors />
    <Copyright />
  </PageContainer>
)

export default IndexPage
