import styled from 'styled-components'
import banner from '../../images/banner.svg'

const Banner = styled.img.attrs({
  src: banner
})`
  right: 22%;
  object-fit: contain;
  width: 100px;
  transform: ${({ show }) => (show ? 'translateY(100%)' : 'translateY(0)')};
  transition: transform 1s linear;
  z-index: 99;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`

export default Banner
