import img1 from '../../images/instagram/webp/picture4.webp'
import img2 from '../../images/instagram/webp/picture5.webp'
import img3 from '../../images/instagram/webp/picture6.webp'
import img4 from '../../images/instagram/webp/picture3.webp'
import img5 from '../../images/instagram/webp/picture7.webp'
import img6 from '../../images/instagram/webp/picture8.webp'
import img8 from '../../images/instagram/webp/picture12.webp'
import img9 from '../../images/instagram/webp/picture1.webp'
import img10 from '../../images/instagram/webp/picture10.webp'

export default {
  row1: [
    {
      src: img1,
      alt: 'MAKE'
    },
    {
      src: img2,
      alt: 'MAKE'
    },
    {
      src: img3,
      alt: 'MAKE'
    }
  ],
  row2: [
    {
      src: img4,
      alt: 'MAKE'
    },
    {
      src: img5,
      alt: 'MAKE'
    },
    {
      src: img6,
      alt: 'MAKE'
    },
  ],
  row3: [
    {
      src: img8,
      alt: 'MAKE'
    },
    {
      src: img9,
      alt: 'MAKE'
    },
    {
      src: img10,
      alt: 'MAKE'
    }
  ]
}
