import styled from 'styled-components'
import theme from '../../theme'

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 60px;
`

export const Header = styled.h1`
  font-family: ${theme.fonts.body.family};
  font-weight: ${theme.fonts.bold.weight};
  font-size: 40px;
  font-style: italic;
  text-align: left;
  color: ${({ accent }) =>
    accent ? theme.colors.fonts.accent : theme.colors.fonts.primary};
  text-align: center;
`

export const Title = styled.h1`
  position: absolute;
  top: -0.6em;
  left: 0.5em;
  font-family: ${theme.fonts.bold.family};
  font-weight: ${theme.fonts.bold.weight};
  font-size: 10vw;
  letter-spacing: 0.3em;
  font-style: italic;
  color: ${theme.colors.fonts.primary};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
`
export const Tier = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const Logo = styled.img`
  width: calc(45% - 240px);
  height: 100%;
  margin: 30px;
  object-fit: contain;

  @media only screen and (max-width: 716px) {
    width: calc(50% - 80px) !important;
    margin: 20px;
  }
`
