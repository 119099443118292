import styled from 'styled-components'
import logo from '../../images/logo.png'
import theme from '../../theme'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
  min-height: 700px;

  @media only screen and (max-width: 700px) {
    padding-bottom: 0;
    min-height: 105vh;
  }
`

export const Logo = styled.img.attrs({
  src: logo
})`
  width: 8.5vw;
  height: 10vw;
  margin-bottom: 3vw;
  
  @media only screen and (max-width: 700px) {
    width: 15vw;
    height: 17.5vw;
    margin: auto;
    margin-bottom: 5vw;
  }
`

export const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const TitleWrapper = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 10vw;
  margin-bottom: 120px;
`

export const Title = styled.h1`
  font-family: ${theme.fonts.italic.family};
  font-weight: ${theme.fonts.italic.weight};
  font-style: italic;
  font-size: 4vw;
  margin-top: 2px;
  color: ${theme.colors.fonts.primary};

  @media only screen and (max-width: 1000px) {
    font-size: 6.5vw;
  }

`

export const BigTitle = styled.h1`
  font-family: ${theme.fonts.italic.family};
  font-weight: ${theme.fonts.italic.weight};
  font-style: italic;
  font-size: 6.5vw;
  color: ${theme.colors.fonts.primary};

  @media only screen and (max-width: 1000px) {
    font-size: 9vw;
  }

`

export const Dates = styled.div`
  font-family: ${theme.fonts.italic.family};
  font-weight: ${theme.fonts.italic.weight};
  font-size: 20px;
  color: ${theme.colors.fonts.primary};
  margin-top: 5px;
`
