import React from 'react'
import { Container, Header, QuestionWrapper, Question, Answer } from './styles'
import QUESTIONS from './constants'

const FAQ = () => {
  return (
    <Container id="faq">
      <Header accent>GOT A QUESTION?</Header>
      <Header>WE GOT YOU.</Header>
      <br/>
      {QUESTIONS.map(({ question, answer }) => (
        <QuestionWrapper>
          <Question>{question}</Question>
          <Answer>{answer}</Answer>
        </QuestionWrapper>
      ))}
    </Container>
  )
}

export default FAQ
