import React, { useRef } from 'react'
import { Container, NavTarget, NavSlice, NavItem } from './styles'
import MobileNav from './MobileNav'

const Navbar = () => {
  const nav = useRef()

  const open = () => nav.current.classList.add('open')

  const close = () => nav.current.classList.remove('open')

  const toggle = () => nav.current.classList.toggle('open')

  const scrollToSection = section =>
    window.scrollTo({
      top: document.getElementById(section).offsetTop - 50,
      left: 0,
      behavior: 'smooth'
    })
  return (
    <>
      <MobileNav scrollToSection={scrollToSection} />
      <Container ref={nav} onMouseLeave={close} onClick={toggle}>
        <NavTarget onMouseEnter={open}>
          <NavSlice />
          <NavSlice />
          <NavSlice />
        </NavTarget>
        <NavItem onClick={() => scrollToSection('about')}>about</NavItem>
        <NavItem onClick={() => scrollToSection('faq')}>faq</NavItem>
        <NavItem onClick={() => scrollToSection('sponsors')}>sponsors</NavItem>
      </Container>
    </>
  )
}

export default Navbar
