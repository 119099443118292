import styled from 'styled-components'
import { Link } from 'gatsby'
import theme from '../../theme'

export const Container = styled(Link)`
  position: ${({ mobile }) => (mobile ? 'static' : 'fixed')};
  bottom: 0px;
  display: grid;
  place-items: center;
  width: 100%;
  height: 80px;
  font-style: italic;
  box-shadow: 20px 20px 100px 20px rgba(0,0,0,0.9);
  background-color: ${theme.colors.layout.primary};
  z-index: 3;
  transition: background-color 0.225s ease;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${theme.colors.layout.secondary};
  }
`

export const Apply = styled.h1`
  font-family: ${theme.fonts.bold.family};
  font-weight: ${theme.fonts.bold.weight};
  font-size: 23px;
  color: ${theme.colors.fonts.primary};
  user-select: none;
`
