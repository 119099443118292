import React, { useRef } from 'react'
import { Container, Target, MiniLogo, NavItem } from './styles'

const MobileNav = ({ scrollToSection }) => {
  const nav = useRef()

  const toggle = () => nav.current.classList.toggle('open')

  return (
    <Container ref={nav}>
      <MiniLogo />
      <Target onClick={toggle} />
      <NavItem
        onClick={() => {
          scrollToSection('about')
          toggle()
        }}
      >
        about
      </NavItem>
      <NavItem
        onClick={() => {
          scrollToSection('faq')
          toggle()
        }}
      >
        faq
      </NavItem>
      <NavItem
        onClick={() => {
          scrollToSection('sponsors')
          toggle()
        }}
      >
        sponsors
      </NavItem>
    </Container>
  )
}

export default MobileNav
