import React from 'react'
import { Container, Header, Wrapper, Tier, Logo } from './styles'
import SPONSORS from './constants'

const Sponsors = () => (
  <Container id="sponsors">
    <Header accent>THESE SPONSORS</Header>
    <Header>MADE MAKEHARVARD POSSIBLE</Header>
    <Wrapper>
      <Tier>
        {SPONSORS.tier1.map(({ src, alt }) => (
          <Logo key={alt} src={src} alt={alt} />
        ))}
      </Tier>
      <Tier>
        {SPONSORS.tier2.map(({ src, alt }) => (
          <Logo key={alt} src={src} alt={alt} />
        ))}
      </Tier>
      <Tier>
        {SPONSORS.tier3.map(({ src, alt }) => (
          <Logo key={alt} src={src} alt={alt} />
        ))}
      </Tier>
    </Wrapper>
  </Container>
)

export default Sponsors
