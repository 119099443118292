import React from 'react'
import ReactPlayer from 'react-player'
import { Logo, Container, BigTitle, Title, Dates, TitleWrapper } from './styles'
import video from '../../images/landingVideo.mp4'
import theme from '../../theme'
import Navbar from '../Navbar'
import SocialMedia from '../SocialMedia'
import ApplyButton from '../ApplyButton'
import MLHBanner from '../MLHBanner'
import './video.css';

const Landing = () => (
  <Container>
    <Navbar />
    <MLHBanner />
    <ReactPlayer style={{
      width: '100%',
      position: 'absolute',
      height: '100%',
    }} width='100%' height='100%' url={video} playing loop muted playsinline />

    <TitleWrapper>
      <Logo />
      <Title>
        MAKE<span style={{ color: theme.colors.fonts.accent }}>STUFF.</span>
      </Title>
      <Title>
        MAKE<span style={{ color: theme.colors.fonts.accent }}>MEMORIES.</span>
      </Title>
      <BigTitle>
        MAKE<span style={{ color: theme.colors.fonts.accent }}>HARVARD.</span>
      </BigTitle>
      <Dates style={{ fontSize: '3vw' }}>2.8.25 - 2.9.25</Dates>
    </TitleWrapper>

    <ApplyButton />
  </Container>
)

// Put back <ApplyButton /> after </TitleWrapper> when application season reopens

export default Landing
