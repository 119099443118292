import styled from 'styled-components'
import logo from '../../../images/logo.png'
import theme from '../../../theme'

export const Target = styled.div`
  position: fixed;
  font-size: 30px;
  height: 30px;
  width: 30px;
  top: 40px;
  right: 40px;
  z-index: 1000;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 1em;
    height: 0.1em;
    top: 0.45em;
    right: 0;
    background: white;
    border-radius: 2px;
    transition: 0.2s ease-out;
  }

  &:before {
    transform: rotate(90deg);
  }
`

export const Container = styled.div`
  position: fixed;
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 80px 0;
  box-sizing: border-box;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: -100vh;
  z-index: 100;
  transition: top 0.3s ease-out;
  background-color: ${theme.colors.layout.tertiary};

  @media only screen and (max-width: 600px) {
    display: flex;
  }

  &.open {
    top: 0;

    ${Target} {
      transform: rotate(45deg);
    }
  }
`

export const MiniLogo = styled.img.attrs({
  src: logo
})`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 45px;
  height: 50px;
`

export const NavItem = styled.div`
  font-family: ${theme.fonts.bold.family};
  font-weight: ${theme.fonts.bold.weight};
  font-size: 36px;
  color: ${theme.colors.fonts.primary};
  cursor: pointer;
  user-select: none;

  &:hover {
    color: ${theme.colors.fonts.accent};
  }
`
